import { render, staticRenderFns } from "./creatBargain.vue?vue&type=template&id=3b5da284&scoped=true"
import script from "./creatBargain.vue?vue&type=script&lang=js"
export * from "./creatBargain.vue?vue&type=script&lang=js"
import style0 from "./creatBargain.vue?vue&type=style&index=0&id=3b5da284&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5da284",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\XMY_WROK\\project_wrok\\hny-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b5da284')) {
      api.createRecord('3b5da284', component.options)
    } else {
      api.reload('3b5da284', component.options)
    }
    module.hot.accept("./creatBargain.vue?vue&type=template&id=3b5da284&scoped=true", function () {
      api.rerender('3b5da284', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/marketing/bargain/bargainGoods/creatBargain.vue"
export default component.exports