"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _financial = require("@/api/financial");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _ZBParser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/ZBParser"));
var _permission = require("@/utils/permission");
var _contract = _interopRequireDefault(require("./contract"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: "AccountsBill",
  components: {
    cardsData: _index.default,
    zbParser: _ZBParser.default,
    ChargeContract: _contract.default
  },
  data: function data() {
    return {
      editData: {},
      isCreate: 1,
      cardLists: [],
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        uid: "",
        // paid: '',
        dateLimit: "",
        status: "",
        keywords: "",
        page: 1,
        limit: 20
      },
      fromList: this.$constants.fromList,
      dialogVisible: false,
      // 新增操作
      dialogTitle: '',
      dialogItem: {},
      dialogItemVisible: false
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getStatistics();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    resetForm: function resetForm(formValue) {
      this.handleClose();
    },
    handlerSubmit: function handlerSubmit(formValue) {
      var _this = this;
      (0, _financial.refundApi)(formValue).then(function (data) {
        _this.$message.success("操作成功");
        _this.dialogVisible = false;
        _this.editData = {};
        _this.getList();
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.editData = {};
    },
    handleRefund: function handleRefund(row) {
      if (row.price == row.refundPrice) return this.$message.waiting("已退完支付金额！不能再退款了 ！");
      if (row.rechargeType === "balance") return this.$message.waiting("佣金转入余额，不能退款 ！");
      this.editData.orderId = row.orderId;
      this.editData.id = row.id;
      this.dialogVisible = true;
    },
    handleDelete: function handleDelete(row, idx) {
      var _this2 = this;
      this.$modalSure().then(function () {
        (0, _financial.topUpLogDeleteApi)({
          id: row.id
        }).then(function () {
          _this2.$message.success("删除成功");
          _this2.getList(_this2.tableFrom.page);
        });
      });
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(",") : "";
      this.tableFrom.page = 1;
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _financial.topUpLogListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 统计
    getStatistics: function getStatistics() {
      var _this4 = this;
      (0, _financial.balanceApi)().then(function (res) {
        var stat = res;
        _this4.cardLists = [{
          name: "充值总金额",
          count: stat.total,
          color: "#1890FF",
          class: "one",
          icon: "iconchongzhijine"
        },
        // { name: '小程序充值金额', count: stat.routine, color:'#A277FF',class:'two',icon:'iconweixinzhifujine' },
        // { name: '公众号充值金额', count: stat.weChat, color:'#EF9C20',class:'three',icon:'iconyuezhifujine1' },
        {
          name: "转账汇款充值金额",
          count: stat.transfer,
          color: "#A277FF",
          class: "three",
          icon: "iconyuezhifujine2"
        }, {
          name: "支付宝充值金额",
          count: stat.alipay,
          color: "#EF9C20",
          class: "three",
          icon: "iconzhifuyongjinjine1"
        }];
      });
    },
    // 操作
    handle: function handle(item, type) {
      this.dialogItem = JSON.parse(JSON.stringify(item));
      switch (type) {
        case "contractExamine":
          this.dialogTitle = "审核";
          this.dialogType = "contractExamine";
          this.viewDetail(item);
          break;
        case "contractView":
          this.dialogTitle = "明细";
          this.dialogType = "contractView";
          this.viewDetail(item);
          break;
      }
    },
    handleItemOk: function handleItemOk() {
      this.dialogItemVisible = false;
      this.getList(1);
    },
    handleItemClose: function handleItemClose() {
      this.dialogItemVisible = false;
    },
    viewDetail: function viewDetail(v) {
      if (!v) return this.$message.error("获取信息失败~");
      this.dialogItemVisible = true;
    },
    // 导出
    exports: function exports() {
      var exportParams = JSON.parse(JSON.stringify(this.tableFrom));
      exportParams.page = 1;
      exportParams.limit = 100 * 10000;
      (0, _financial.topUpLogListDownApi)(exportParams).then(function (res) {
        window.location.href = res.fileName;
      });
    }
  }
};